#RecipesDiv {
  .cuisine {
    position: absolute;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    right: 2px;
    bottom: 0px;
    padding: 2px 6px 3px 5px;
    border-radius: 3px;
  }

  .theme-dark {
    .cuisine {
      background-color: rgba(255, 10, 50, 0.75);
      transition: background-color 500ms;
    }

    img {
      //cursor: zoom-in;
      cursor: url(../../images/cursors/eyeCursorDark.svg), zoom-in;
    }
  }

  .theme-light {
    .cuisine {
      background-color: rgba(223, 10, 56, 0.75);
      transition: background-color 500ms;
    }

    img {
      //cursor: zoom-in;
      cursor: url(../../images/cursors/eyeCursorLight.svg), zoom-in;
    }
  }
}

#RecipesOptionsDivInRecipes {
  position: absolute;
  top: 100px;
  grid-column-gap: 15px;
  grid-row-gap: 11px;
  //grid-column-gap: 30px
  display: grid;
  width: 100vw;
  left: 50%;
  padding-bottom: 80px;
  transform: translateX(-50%);
  grid-template-columns: repeat(auto-fill, 236px);
  justify-content: center;

  .recipeCardInRecipes {
    img {
      position: relative;
      width: 236px;
      border-radius: 16px;
    }
  }

  .recipeCardInRecipes:hover {
    img {
      opacity: 0.7;
    }
  }

  .iconsInRecipeCard {
    position: relative;
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  #RecipesOptionsDivInRecipes {
    top: 5px;
  }
}

@media only screen and (max-width: 768px) {
  #RecipesOptionsDivInRecipes {
    grid-template-columns: repeat(auto-fill, 186px);

    .recipeCardInRecipes {
      img {
        position: relative;
        width: 186px;
      }
    }

    .cuisine {
      right: 10px;
      bottom: auto;
      top: 10px;
    }
  }
}

@media only screen and (max-width: 0px) {
  #RecipesOptionsDivInRecipes {
    grid-template-columns: repeat(auto-fill, 176px);

    .recipeCardInRecipes {
      img {
        position: relative;
        width: 176px;
      }
    }
  }
}

@media only screen and (max-width: 389px) {
  #RecipesOptionsDivInRecipes {
    grid-template-columns: repeat(auto-fill, 136px);

    .recipeCardInRecipes {
      img {
        position: relative;
        width: 136px;
      }
    }
  }
}
