#TextsInRecipe {
  margin-left: 5px;
}

#RecipeCardTextTitle {
  width: 100%;
  text-align: center;
  height: 60px;
  font-size: 150%;
}

#RecipeCardTextBody {
  //background-color: yellow;
  height: calc(100% - 24px);
  max-height: calc(100% - 60px);
  overflow-x: auto;
  padding-right: 50px;
  padding-left: 10px;
  font-weight: 300;
}
