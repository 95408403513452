#AppOptionsButton {
  position: relative;
  padding: 0;
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  cursor: pointer;
  transform: scale(1.5) translate(0, 3px);
  background-color: transparent;

  svg {
    pointer-events: none;
    cursor: pointer;
    transform: translate(-5px, -3px);
  }
}

@media only screen and (max-width: 1024px) {
  #AppOptionsSection {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-self: center;
    justify-content: center;
  }

  #AppOptionsButton {
    //background-color: rgba(40, 40, 40, 1);
    box-shadow: -1px -1px 2px rgba(90, 90, 90, 9), 1px -1px 2px rgba(90, 90, 90, 9), -1px 1px 2px rgba(90, 90, 90, 9), 1px 1px 2px rgba(90, 90, 90, 9);
    //left: 50%;
    //transform: translate(-50%, -12px) scale(2.6);
    transform: translateY(-22px) scale(1.5);
    border-radius: 50%;
    padding: 8px;
    //padding: 7px 6px 6px 7px;

    svg {
      transform: scale(1) translate(-3px, -3px);
    }

    .theme-dark {
      //background-color: black;
      background-color: rgba(40, 40, 40, 1);
      transition: background-color 500ms, color 500ms;
    }

    .theme-light {
      //background-color: white;
      background-color: rgba(160, 160, 160, 1);
      transition: background-color 500ms, color 500ms;
    }
  }
}

@media only screen and (max-width: 450px) {
  #AppOptionsButton {
    //transform: translateY(-22px) scale(1.5);
  }
}
