#TagsDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  //padding-bottom: 100px;
  gap: 10px;
  top: 5px;
  height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  //height: 100vh;
  width: calc(100% - 35px);
  overflow: auto;

  .tagsTitle {
    pointer-events: none;
    font-size: 16px;
  }

  .tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1024px) {
  #TagsDiv {
    width: calc(100vw - 20px);

    .tagsTitle {
      pointer-events: none;
      font-size: 18px;
    }
  }
}
