#SearchDivDesktop {
  position: relative;
  flex-grow:1;
  max-height: 50px;
}

#SearchIconInTextbox {
  //pointer-events: none;
  //cursor: text;
  position: absolute;
  left: 20px;
  top: 23px;
}

#SearchTextboxDiv {
  position: absolute;
  left: 0;
  top: 6px;
  width: calc(100% - 40px);
  height: 50px;

  input {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 16px;
    outline: none;
    border-radius: 30px;
    border: 0px;
    max-height: 50px;
    padding-left: 45px;
  }
}

#SearchButtonForSuggstions {
  position: absolute;
  display: none;
  cursor: pointer;
  right: -25px;
  top: 10px;
  width: 40px;
  height: 30px;
  padding-top: 3px;
  background-color: rgb(50, 50, 50);
  filter: brightness(100%);
  color: white;
  border-radius: 10px;
  text-align: center;
  //text-decoration: none;

  &.theme-dark {
    background-color: rgb(205, 205, 205);
    color: black
  }

  &:hover {
    filter: brightness(80%);
  }
}

#CancelSearchButton {
  position: fixed;
  left: 20px;
  top: 10px;
  //bottom: 10px;
  /*width: 100vw;
  height: 40px;
  text-align: center;
  //background-color: #bebebe;
  padding-top: 10px;

  &.theme-dark {
    background-color: #636363;
  }

  &.theme-light {
    background-color: #bebebe;
  }*/

  svg {
    transform: scale(1.4);
  }
}

@media only screen and (max-width: 1024px) {
  //#SearchDiv {
  #SearchDiv {
    position: fixed;
    left: 10px;
    top: 30px;
    width: calc(100vw - 20px);
    margin-right: 10px;
    //z-index: 10000000000;
  }



    #SearchIconInTextbox {
      //svg {
        transform: translateY(1px);
      //}
    }

    #SearchTextbox {
      position: absolute;
      margin-top: 10px;
      width: calc(100% - 50px);
    }

    #SearchIconInTextbox {
      top: 30px;
    }

    #SearchButtonForTags {
      position: fixed;
      bottom: 0;
      border-radius: 0;
      margin: 0;
      width: 100vw;
      //border-bottom: 1px red solid;
    }

    #SearchButtonForSuggstions {
      top: 20px;
    }
}

/*@media only screen and (max-width: 360px) {
  #SearchDiv {
    #SearchIconInTextbox {
      svg {
        transform: scale(1.2) translate(-10px, -16px);
      }
    }
  }
}*/
