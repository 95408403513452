.videoActionsDiv {
  position: relative;
  display: flex;
  margin-top: 15px;

  svg {
    pointer-events: none;
  }

  .actionsRowDiv {
    position: relative;
    display: flex;
    gap: 20px;
    bottom: 5px;
    //margin-top: 10px;

    .videoActionIcon {
      cursor: pointer;
      //margin-top: 7px;
    }

    .playVideoIcon {
      transform: scale(1.5) translate(2px, 2px);
    }
  }
}

@keyframes addImgToWatchLater {
  0% {
    opacity: 0.95;
  }
  80%{
    position: fixed;
    opacity: 0.95;
    right: 50px;
    top: 70px;
    transform: scale(1);
  }

  100%{
    right: 0;
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes addImgToWatchLaterMobile {
  0% {
    opacity: 0.95;
  }
  80%{
    position: fixed;
    opacity: 0.95;
    left: calc(50% - 32px);
    bottom: 120px;
    transform: scale(1);
  }

  100%{
    //right: 0;
    bottom: 30px;
    transform: scale(0) ;
    opacity: 0;
  }
}

/*.videoActionsDiv {
  position: relative;
  display: flex;


  svg {
    pointer-events: none;
  }

  .actionsRowDiv {
    position: absolute;
    display: flex;
    //justify-content: center;
    //vertical-align: middle;
    gap: 1.5vw;
    left: 0;
    bottom: 0;
    background-color: black;
    border-radius: 5px;
    //padding: 10px;
    opacity: 0.8;
  }

  .videoActionIcon {
    cursor: pointer;
    border-radius: 20px;
    z-index: 99999999;
    transform: scale(1.25);

    &:hover {
      opacity: 0.6;
    }
  }
}
*/
