#ThemeRow {
  .theme-dark {
    svg {
      fill: rgb(190, 190, 190);
      transition: fill 500ms;
    }
  }

  .theme-light {
    svg{
      fill: #F5B027;
      transition: fill 500ms;
    }
  }
}
