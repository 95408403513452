#FollowUsBox {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 999999999;
}

#FollowUsDiv {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100vw;
  max-width: 600px;
  height: 350px;
  transform: translate(-50%, -50%);
}

#FollowUsList {
  div {
    position: absolute;
    right: 0;
    width: 0;
    height: 300px;
    cursor: pointer;
    overflow: hidden;
    filter: brightness(85%);
  }

  div:hover {
    transform: scale(1.1);
    z-index: 99999999999;
    border-radius: 5px;
    transition: transform 200ms, border-radius 200ms;
    filter: brightness(100%);
    //opacity: 0.9;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.5) rotate(-180deg);
    border: 1px solid #cccccc;
    border-radius: 50%;
    padding: 5px;
    animation: spinSVG 1s ease forwards;
  }
}

#FollowUsOnPinterestDiv {
  animation: display1 600ms ease forwards;
  background-color: #e60023;

  svg {
    fill: #cccccc;
  }
}

#FollowUsOnYouTubeDiv {
  animation: display4 600ms ease forwards;
  background-color: #FF0000;

  svg {
    fill: #cccccc;
  }
}

#FollowUsOnInstagramDiv {
  animation: display3 600ms ease forwards;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);

  svg {
    fill: #cccccc;
  }
}

#FollowUsOnFacebookDiv {
  animation: display2 600ms ease forwards;
  background-color: #3b5998;

  svg {
    fill: #cccccc;
  }
}

#FollowUsOnTikTokDiv {
  animation: display5 600ms ease forwards;
  background-image: linear-gradient(to bottom right, #FD3E3E, black, #4DE8F4);

  svg {
    fill: #cccccc;
  }
}

#FollowUsOnTwitterDiv {
  animation: display6 600ms ease forwards;
  background-color: #121212;

  svg {
    fill: #cccccc;
  }
}

@media only screen and (max-width: 600px) {
  #FollowUsOnPinterestDiv {
    animation: mobileDisplay1 600ms ease forwards;
  }

  #FollowUsOnYouTubeDiv {
    animation: mobileDisplay4 600ms ease forwards;
  }

  #FollowUsOnInstagramDiv {
    animation: mobileDisplay3 600ms ease forwards;
  }

  #FollowUsOnFacebookDiv {
    animation: mobileDisplay2 600ms ease forwards;
  }

  #FollowUsOnTikTokDiv {
    animation: mobileDisplay5 600ms ease forwards;
  }

  #FollowUsOnTwitterDiv {
    animation: mobileDisplay6 600ms ease forwards;
  }
}

@keyframes spinSVG {
  50%{
    transform: translate(-50%, -50%) scale(1.5) rotate(-180deg);
  }
  80%{
    transform: translate(-50%, -50%) scale(1.5) rotate(20deg);
  }
  90%{
    transform: translate(-50%, -50%) scale(1.5) rotate(-20deg);
  }
  100%{
    transform: translate(-50%, -50%) scale(1.5) rotate(0deg);
  }
}

@keyframes display1 {
  50%{
    width: 0;
  }
  100%{
    width: 100px;
    right: 0;
  }
}

@keyframes display2 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 100px;
    right: 100px;
  }
}

@keyframes display3 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 100px;
    right: 200px;
  }
}

@keyframes display4 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 100px;
    right: 300px;
  }
}

@keyframes display5 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 100px;
    right: 400px;
  }
}

@keyframes display6 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 100px;
    right: 500px;
  }
}

@keyframes mobileDisplay1 {
  50%{
    width: 0;
  }
  100%{
    width: 17vw;
    min-width: 50px;
    max-width: 100px;
    right: 0;
  }
}

@keyframes mobileDisplay2 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 17vw;
    min-width: 50px;
    max-width: 100px;
    right: 17vw;
  }
}

@keyframes mobileDisplay3 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 17vw;
    min-width: 50px;
    max-width: 100px;
    right: 34vw;
  }
}

@keyframes mobileDisplay4 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 17vw;
    min-width: 50px;
    max-width: 100px;
    right: 51vw;
  }
}

@keyframes mobileDisplay5 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 17vw;
    min-width: 50px;
    max-width: 100px;
    right: 68vw;
  }
}

@keyframes mobileDisplay6 {
  50%{
    width: 0;
    right: 0;
  }
  100%{
    width: 17vw;
    min-width: 50px;
    max-width: 100px;
    right: 85vw;
  }
}
