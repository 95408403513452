#ButtonsInRecipesCardDiv {
  position: absolute;
  right: 20px;
  bottom: 40px;
  width: 30px;
  height: 345px;
  //transform: translateY(-50%);
  //z-index: 999999999;

  & .theme-dark, & .theme-light {
    background-color: transparent;
  }
}

#RecipesIconsDiv {
  display: flex;
  align-items: center;
  //height: 100%;
  flex-direction: column;
  gap: 20px;
  transform: scale(1.15);
  //width: 35px;
  //align-items: center;
  justify-content: center;

  .buttonInRecipe {
    display: flex;
    cursor: pointer;
    //width: 30px;
    //height: 30px;
    //border-radius: 50%;
  }

/*  svg {
    fill: black;
    stroke: black;
    stroke-width: 0.1;
    transition: fill 500ms;
  }*/
}

@media only screen and (max-width: 700px) {
  #ButtonsInRecipesCardDiv {
    position: fixed;
    right: auto;
    bottom: 170px;
    //top: 20px;
    transform: none;



    & .theme-dark {
      //background-color: rgba(24, 25, 26, 0.6);

      svg {
        fill: white;
        stroke: white;
        stroke-width: 0.5;
      }
    }

    & .theme-light {
      //background-color: rgba(238, 238, 238, 0.6);

      svg {
        fill: black;
        stroke: black;
        stroke-width: 0.5;
      }
    }
  }

  #RecipesIconsDiv {
    gap: 25px;
    transform: translatex(-10px) scale(1.3);
  }
}

/*@media only screen and (max-width: 800px) {
  #ButtonsInRecipesCardDiv {
    position: fixed;
    right: 80px;
    top: 0;
  }
}
#DivGapInRecipe {
  display: flex;
  flex-grow: 1;
}*/
