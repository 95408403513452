#TopTextInHome {
  font-family: Babylonica, cursive;
  text-align: center;
  font-size: 25px;
  padding: 100px 0px 20px 0px;
}

#SignatureOptionsDiv {
  display: flex;
  gap: 1.2vw;
  flex-flow: nowrap;
  justify-content: center;
  padding: 3vw;

  & :hover {
    opacity: 0.85;
  }

  &.theme-dark {
    background-color: #242526;
  }

  &.theme-light {
    background-color: white;
  }

  img {
    width: 15vw;
    border-radius: 2px;
  }
}

.recipesOptionDiv {
  //position: relative;
  cursor: pointer;
}

.recipeTitle {
  display: flex;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  bottom: -0.6vw;
  padding: 5px 13px;
  width: auto;
  text-align: center;
  font-size: 1vw;
  white-space: nowrap;
  opacity: 0.9;
  font-weight: 400;
  text-transform: lowercase;
}

#PostsDiv {
  position: relative;
  display: flex;
  flex-direction: column,
}

#LoadMoreButton {
  display: flex;
  align-self: center;
  background-color: rgb(200, 200 ,200);
  color: rgb(40, 40, 40);
  font-weight: 700;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  #TopTextInHome {
    display: none;
  }

  #SignatureOptionsDiv {
    padding: 5px;

    img {
      width: 23vw;
      max-width: 150px;
    }

    .recipeTitle {
      font-size: 11px;
      font-weight: 300;
    }
  }
}
