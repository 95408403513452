.post {
  align-self: center;
  width: 100%;
  max-width: 500px;
  height: auto;
  //margin: 10px;
  padding: 5px 0 15px 0;
  border-radius: 15px;
  //background-color: rgba(35,35,35,1);

  &.theme-light {
    background-color: white;
  }

  &.theme-dark {
    background-color: #242526;
  }

  img {
    width: 100%;
  }

  .recipeImagInPost {
    cursor: pointer;
  }

  .recipeImagInPost:hover {
    //opacity: 0.9;
  }

  margin: 20px;

  .postIcons {
    position: relative;
    display: flex;
    gap: 30px;
    margin: 20px 15px 0 15px;
  }

  .bottomRightPost {
    position: absolute;
    display: flex;
    gap: 20px;
    font-size: 12px;
    font-weight: 300;
    right: 5px;
    bottom: 10px;

    .originPost, .totalTimePost {
      display: flex;
      gap: 3px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .post {
    .postIcons {
      gap: 45px;

      svg {
        transform: scale(1.1);
      }
    }
  }
}

/*@media only screen and (max-width: 410px) {
  .post {
    .postIcons {
      gap: 55px;
    }
  }
}*/

@media only screen and (max-width: 389px) {
  .post {
    .postIcons {
      gap: 20px;

      svg {
        transform: scale(1);
      }
    }
  }
}
