#OptionsDiv {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  right: 30px;
  top: 60px;
  width: 140px;
  height: 267px;
  max-height: calc(100% - 140px);
  border-radius: 10px;
  overflow: auto;
  animation: displayOptions 50ms ease forwards;

  .optionRow {
    display: flex;
    gap: 20px;
    height: 30px;
    padding: 5px 0px 0px 10px;
    cursor: pointer;
    font-weight: 200;
    border-radius: 5px;
    color: inherit;
    text-decoration: none;


  }

  &.theme-dark {
    background-color: rgba(0, 0, 0, 1);

      .optionRow:hover {
        background-color: #202020;
        transition: background-color 200ms
      }

  }

  &.theme-light {
    background-color: rgba(255, 255, 255, 1);

    .optionRow:hover {
      background-color: #dddddd;
      transition: background-color 200ms;
    }
  }

  .iconInOption {
    max-width: 20px;
    pointer-events: none;
  }

  .textInOption {
    max-width: 100px;
    pointer-events: none;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1024px) {
  #OptionsDiv {
    right: auto;
    top: auto;
    left: 50%;
    bottom: -230px;
    transform: translateX(-50%) scale(1.1);
    animation: displayOptionsMobile 200ms ease forwards;

    & :nth-child(1) { order: 1; }
    & :nth-child(2) { order: 2; }
    & :nth-child(3) { order: 3; }
    & :nth-child(4) { order: 4; }
    & :nth-child(5) { order: 5; }

    svg {
      transform: scale(1.5);
    }
  }
}

@media only screen and (max-width: 500px) {
  #OptionsDiv {
    animation: displayOptionsMobileSMLScrn 200ms ease forwards;
  }
}


@media only screen and (max-height: 410px) {
  #OptionsDiv {
    //transform: scale(0.95) translate(-80px, 20px);
    //max-height: calc(100vh - 50px);
  }
}

@media only screen and (max-height: 355px) {
  #OptionsDiv {
    //transform: scale(0.9) translate(-80px, 30px);
    //max-height: calc(100vh - 50px);
  }
}

@keyframes displayOptions {
  0%{
    height: 0px;
  }
  100%{
    height: 267px;
    //height: calc(100vh - 80px);
  }
}

@keyframes displayOptionsMobile {
  100%{
    bottom: 110px;
  }
}

@keyframes hideOptionsMobile {
  100%{
    bottom: -230px;
  }
}

@keyframes displayOptionsMobileSMLScrn {
  100%{
    bottom: 100px;
  }
}
