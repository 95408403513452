#RecipePageDiv {
  position: fixed;
  left: 0;
  top: 85px;
  width: 100vw;
  height: calc(100vw - 85px);
}

@media only screen and (max-width: 1024px) {
  #RecipePageDiv {
    top: 0;
  }
}
