*{
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

body {
  margin: 0;
  background-color: #18191a;
  font-family: Oswald, monospace, sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;


  @media only screen and (max-width: 1024px) {
    svg {
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 370px) {
    svg {
      transform: scale(1);
    }
  }

  /*input {
    background-color: #18191a;
    color: white;
    ::selection {
      color: red;
      background-color: green;
    }
  }*/
  transition: background-color 500ms, color 500ms;
}

svg {
  pointer-events: none;
}

#MiddleSection {
  //margin-top: 100px;
}

.theme-dark {
  background-color: #18191a;
  color: white;

  svg {
    fill: rgb(190, 190, 190);
    stroke: rgb(190, 190, 190);
    stroke-width: 0.1;
    transition: fill 500ms;
  }

  input {
    background-color: #242526;
    color: white;
    /*::selection {
      color: red;
      background-color: green;
    }*/
    transition: background-color 500ms, color 500ms;
  }

  transition: background-color 500ms, color 500ms;
}

.theme-light {
  background-color: #f0f2f5;
  color: #242526;

  svg {
    fill: rgb(34, 34, 34);
    stroke: rgb(34, 34, 34);
    stroke-width: 0.1;
    transition: fill 500ms;
  }

  input {
    background-color: #dddddd;
    color: #18191a;
    /*::selection {
      color: red;
      background-color: green;
    }*/
    transition: background-color 500ms, color 500ms;
  }

  transition: background-color 500ms, color 500ms;
}
