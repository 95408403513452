#MiddleSectionRecipePage {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //height: calc(100% - 85px);
  //left: 0;
  //top: 85px;


}

@media only screen and (max-width: 1024px) {
  #MiddleSectionRecipePage {
    height: calc(100% - 55px);
    top: 0;
  }
}
