@keyframes toPin {
  0%{
    transform: rotate(45deg);
  }
  50%{
    transform: translateY(-10px) rotate(5deg);
  }
  100%{
    transform: rotate(-0deg);
  }
}

@keyframes toUnpin {
  0%{
    transform: rotate(-45deg);
  }
  50%{
    transform: translateY(-10px);
  }
  100%{
    transform: rotate(0deg);
  }
}

@keyframes addImgToPin {
  0% {
    opacity: 0.95;
  }
  80%{
    position: fixed;
    opacity: 0.95;
    right: 50px;
    top: 70px;
    transform: scale(1);
  }

  100%{
    right: 0;
    top: 0;
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes addImgToPinMobile {
  0% {
    opacity: 0.95;
  }
  80%{
    position: fixed;
    opacity: 0.95;
    left: calc(50% - 32px);
    bottom: 120px;
    transform: scale(1);
  }

  100%{
    //right: 0;
    bottom: 30px;
    transform: scale(0) ;
    opacity: 0;
  }
}
