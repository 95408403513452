#SearchSeggesionsDiv {
  margin: -10px -10px 10px -10px;
  height: 100vh;
  overflow: auto;

  .suggesionRow {
    padding: 10px;
    cursor: pointer;
    text-transform: lowercase;

    span {
      margin-left: 7px;
    }
  }

  .theme-dark:hover {
    background-color: rgba(59, 59, 59, 0.4);
    cursor: pointer;
    transition: background-color 50ms;
  }

  .theme-light:hover {
    background-color: rgba(238, 238, 238, 0.8);
    transition: background-color 50ms;
  }
}
