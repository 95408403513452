.videoCard {
  position: relative;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: transparent;

  img {
    width: 100%;
    border-radius: 5px;
    cursor: default;
  }

  .playVideoButton {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 200;
    padding: 0 4px 2px 4px;
    border-radius: 2px;

    svg {
      transform: scale(5);
      opacity: 0.9;
      pointer-events: auto;
      cursor: pointer;
      display: none;
    }

    svg:hover {
      opacity: 1;
    }
  }

  .cuisine {
    position: absolute;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    right: 0;
    bottom: 15px;
    padding: 2px 6px 3px 5px;
    border-radius: 3px;
  }
}
