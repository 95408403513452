#BackButton {
  position: fixed;
  left: 20px;
  top: 120px;
  transform: scale(1.3);
  cursor: pointer;
  width: 30px;
  height: 30px;
  //z-index: 99999999999999999;
  border-radius: 50%;
  transition: background-color 100ms;

  svg {
    transform: translate(6px, 4px);
  }

  &.theme-dark {
    background-color: rgba(24, 25, 26, 0.6);
  }

  &.theme-light {
    background-color: rgba(238, 238, 238, 0.6);
  }

  &:hover {
    opacity: 0.9;

    &.theme-dark {
      background-color: rgba(40, 40, 40, 0.9);
    }

    &.theme-light {
      background-color: rgba(212, 212, 212, 0.9);
    }
  }
}

@media only screen and (max-width: 1024px) {
  #BackButton {
    top: 60px;
  }
}

@media only screen and (max-width: 700px) {
  #BackButton {
    top: 20px;
  }
}
