#ShareDiv {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999999999;
  background-color: rgba(0, 0, 0, 0.7);
  //display: flex;
  justify-content: center;

  svg {
    fill: white;
  }
}

#ShareBodyDiv {
  position: absolute;
  left: 50%;
  top: calc(50% - 0px);
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 60px;
  padding-bottom: 100px;

  & .iconsSection {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    gap: 30px;
    border-bottom: 1px solid #777777;
    //margin: 40px 4vw 0 4vw;
    padding-bottom: 5px;
    margin-bottom: 20px;
  }

  & .recipesShareIcons {
    cursor: pointer;
    transform: scale(1.5);
  }

  & .recipesShareIcons:hover {
    transform: scale(2) translateY(-5px);
  }
}

#URLInShare {
  position: absolute;
  display: flex;
  left: 50%;
  width: calc(100vw - 100px);
  //max-width: 300px;
  transform: translateX(-50%);
  gap: 10px;
  background-color: #18191a;
  border: 1px solid #777777;
  border-radius: 10px;
  padding: 5px;

  span {
    width: 100%;
    margin-left: 5px;
    color: white;
    padding-top: 8px;
    cursor: text;
    font-size: 14px;
    user-select: text;
    overflow: auto;
  }

  div {
    color: white;
    cursor: pointer;
    padding: 7px 17px 10px 15px;
    border-radius: 10px;
    background-color: rgba(150, 150, 150, 0.5);
    transform: scale(0.8);
  }

  div:hover {
    background-color: rgba(50, 50, 50, 0.9);
  }
}

#ClipboardMSGConfrim {
  position: fixed;
  left: 10px;
  bottom: -45px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  z-index: 999999999999999;
  color: black;
}

#BackButtonInShare {
  position: absolute;
  left: 20px;
  top: 20px;
  transform: scale(1.3);
  cursor: pointer;
  width: 30px;
  height: 30px;
  z-index: 99999999999999999;
  border-radius: 50%;
  //opacity: 0.7;
  transition: background-color 100ms;
  background-color: rgba(0, 0, 0, 0.6);

  svg {
    transform: translate(6px, 4px);
    fill: white;
    stroke: white;
    stroke-width: 1;
  }

  &:hover {
    background-color: rgba(60, 60, 60, 0.9);
  }
}

@media only screen and (max-width: 1024px) {
  #ShareBodyDiv {
    & .recipesShareIcons:hover {
      transform: scale(1.5) translateY(0px);
    }
  }

  #BackButtonInShare {
    top: 60px;
  }

  #ClipboardMSGConfrim {
    left: 50%;
    transform: translateX(-50%);
    top: -45px;
    bottom: auto;
  }
}


@media only screen and (max-width: 700px) {
  #BackButtonInShare {
    top: 20px;
  }
}

@keyframes bringUpConfirmation {
  70% {
    bottom: 15px
  }
  90% {
    bottom: 9px
  }
  100% {
    bottom: 10px
  }
}

@keyframes bringDownConfirmation {
  0% {
    bottom: 10px;
  }
  100% {
    bottom: -45px
  }
}

@keyframes bringUpConfirmationSMLScrn {
  100% {
    top: 10px
  }
}

@keyframes bringDownConfirmationSMLScrn {
  0% {
    top: 10px;
  }
  100% {
    top: -45px
  }
}
