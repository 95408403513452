.tag {
  display: flex;
  gap: 3px;
  padding: 5px 10px 5px 10px;
  font-weight: 200;
  cursor: pointer;
  max-width: 120px;

  div {
    pointer-events: none;
  }

  svg {
    fill: rgb(188, 188, 188);
  }
}
