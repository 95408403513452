.inVideos {
  position: absolute;
  top: 100px;
}

.inSavedVideos {
  position: relative;
  top: 0;
}

#VideoCardsWrapper {
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  display: grid;
  width: 100vw;
  left: 50%;
  padding-bottom: 80px;
  transform: translateX(-50%);
  grid-template-columns: repeat(auto-fill, 236px);
  justify-content: center;

  .theme-dark {
    .cuisine {
      background-color: rgba(255, 10, 50, 1);
      transition: background-color 500ms;
    }
  }

  .theme-light {
    .cuisine {
      background-color: rgba(255, 10, 50, 01);
      transition: background-color 500ms;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #VideoCardsWrapper {
    top: 5px;
  }
}

@media only screen and (max-width: 488px) {
  #VideoCardsWrapper {
    grid-template-columns: repeat(auto-fill, calc(100vw / 2.2));
  }
}

/*@media only screen and (max-width: 367px) {
  #VideoCardsWrapper {
    grid-template-columns: repeat(auto-fill, 136px);
  }
}

@media only screen and (max-width: 327px) {
  #VideoCardsWrapper {
    grid-template-columns: repeat(auto-fill, 100vw);
  }
}*/
