#MainNavDiv {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  width: 100vw;
  z-index: 9999999;
  transition: background-color 500ms;

  .navTextMobile {
    position: absolute;
    top: 18px;
    font-size: 13px;
    font-weight: 200;
    margin-left: -5px;
  }

  .navOptionMobile {
    transform: translateY(-4px);
    color: white;

    svg {
      fill: #bebebe
    }
  }

  #SearchDivMobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    /*& :nth-child(1) {
      //background-color: red;
    }

    & :nth-child(2) {
      //background-color: green;
      right: 10px;
      top: 20px;
      position: fixed;
    }*/

    /*div {
      background-color: red;
      display: flex;
      position: relative;
    }*/

    svg {
      transform: translate(10px, 6px);
    }

    #CancelSearchMobile {////////////////////////////////////////////////////
      position: fixed;
      right: 10px;
      top: 15px;
    }
  }
}

#NavBar {
  margin: 0;
  padding: 10px 20px 10px 20px;
  //font-size: 1.5vw;
  //padding-top: 1.8vw;
  //margin-left: 2.2vw;
  display: flex;
  //text-align: center;
  //justify-content: center;
  gap: 25px;
  width: 100vw;

  .navOption {
    //padding-top: 5px;
    //cursor: pointer;
    /*opacity: 0.5;*/
  }

  .navOption:hover {
    //opacity: 1;
    /*color: green;*/
  }

  .likeButton {
    font-size: 18px;
    margin-top: 18px;
    //width: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  #MainNavDiv {
    display: flex;
    //align-self: center;
    justify-content: center;
    //display: flex;
    //gap: 5vw;
    //left: 50%;
    top: auto;
    bottom: 10px;
    //transform: translateX(-50%);
    width: calc(100vw - 10px);
    height: 60px;
    //border-radius: 20px 20px 0 0;
    border-radius: 30px;
    margin: 0 5px;
    //filter: blur(20%);
    //opacity: 0.9;
    //background-color: rgba(0, 0, 0, 0.8);
    //backdrop-filter: blur(15px);
    box-shadow: -1px -1px 2px #222, 1px -1px 2px #222, -1px 1px 2px #222, 1px 1px 2px #222;
  }

  #NavBar {
    //gap: auto;
    padding: 0;
    align-content: center;
    align-items: center;
    //max-width: auto;

    .likeButton {
      font-size: inherit;
      margin-top: 0;
      //width: 30px;
    }

    & :nth-child(1) { order: 1; }
    & :nth-child(2) { order: 2; }
    & :nth-child(3) { order: 4; }
    & :nth-child(4) { order: 5; }
    & :nth-child(5) { order: 3; }

    .navButton {
      position: relative;
      display: flex;
      flex-grow: 1;
      align-self: center;
      justify-content: center;
      width: 90px;
      height: 24px;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */

      svg {
        transform: scale(1);
        stroke: white;
        //fill: red;
      }
    }

    #AppOptionsButton {
      svg {
        transform: scale(1) translate(-3px, -3px);
      }
    }
  }
}

@media only screen and (max-width: 530px) {
  #NavBar {
    //gap: 25px;
    //transform: scale(0.9);
  }
}

@media only screen and (max-width: 450px) {
  #MainNavDiv {
    height: 50px;
  }

  #NavBar {
    a {
      //transform: scale(0.85);
    }
    gap: 2vw;
    //transform: scale(0.9);
  }
}

@media only screen and (max-width: 360px) {
  #NavBar {
    //gap: 25px;
    //transform: scale(0.8);

    /*.navButton {
      svg {
        transform: scale(0.7);
        //fill: red;
      }
    }*/
  }
}
