#LoadingDiv {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 999999999999999999;
}

#AnimationInLoading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  img {
    position: absolute;
    width: 120px;
    left: calc(50% - 60px);
    top: calc(50% - 60px);
    transform: translate(-50%, -50%);
    animation: zoomIn 3s linear infinite;
  }
}

#LoadingDiv {
  animation: changeOpacity 1.5s linear forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes changeOpacity {
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
