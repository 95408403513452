#SearchResultsBackdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 70px;
}

#SearchResultsDiv {
  position: absolute;
  left: 0;
  top: 75px;
  height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  padding: 20px 20px 20px 20px;
  border-radius: 0 0 15px 15px;

  &.theme-dark {
    background-color: rgba(5, 5, 5, 1);
  }

  &.theme-light {
    background-color: rgba(255, 255, 255, 1);
  }
}

#TagsSearch {
  //position: absolute;
  display: flex;
  font-weight: 200;
  width: 60px;
  height: 30px;
  right: 110px;
  bottom: 20px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
  //background-color: rgba(153, 51, 51, 0.7);
  //border-radius: 5px;
}

#SearchButtonForTags {
  background-color: rgba(99, 99, 99, 1);
  border-radius: 0 0 15px 15px;
  //border-radius: 30px;
  cursor: pointer;
  position: absolute;
  //display: none;
  width: 100%;
  height: 52px;
  left: 0;
  bottom: 0;
  //align-items: center;

  &.theme-dark {
    background-color: #636363;
  }

  &.theme-light {
    background-color: #bebebe;
  }

  svg {
    position: absolute;
    left: 50%;
    transform: scale(1.2) translate(-50%, 15px);
  }
}

#ClearAllTags {
  position: absolute;
  right: 10px;
  bottom: 55px;
  max-width: 92px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  #SearchResultsDiv {
    top: 85px;
    padding: 10px 10px 10px 10px;
    //width: calc(100vw - 45px); set in SearchSection js
    height: calc(100vh - 85px);
    max-height: calc(100vh - 85px);
    border-radius: 0;
  }

  #EnterIconInTextbox {
    position: fixed;
    bottom: 0;
    border-radius: 0;
    margin: 0;
    width: 100vw;
    //border-bottom: 1px red solid;
  }

  #ClearAllTags {
    position: fixed;
    right: 10px;
    bottom: 62px;
  }

  #SearchResultsBackdrop {
    pointer-events: none;
  }
}

@keyframes displaySearchSection {
  0%{
    height: 0px;
  }
  100%{
    height: calc(100vh - 110px);
  }
}

@keyframes hideSearchSection {
  0%{
    height: calc(100vh - 110px);
  }
  100%{
    height: 0px;
  }
}
