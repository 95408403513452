#ButtonOptionTooltip {
  //position: fixed;
  //background-color: rgba(0, 0, 0, 1);
  //color: white;
  font-weight: 100;
  padding: 0px 5px 3px 5px;
  border-radius: 5px;
  margin-top: 9px;
  opacity: 0.90;
  z-index: 999999999999;
  pointer-events: none;
  max-width: 120px;
  //display: none;
  //animation: ;
  //left: 0;
  //top: 0;
}

@keyframes displayToolTip {
  0%{
  display: none;
  }
  100%{
  display: block;
  }
}
