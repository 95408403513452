.numberOfViewsSection {
  display: flex;
  flex-direction: column;
  transform: translateY(2px);

  div {
    display: flex;
    align-self: center;
  }

  .numberOfViewsDiv {
    font-size: 10px;
    font-weight: 300;
  }
}
