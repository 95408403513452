/*#11:active {
  animation: toReact 1s linear infinite;
}*/

.recipeReactDiv {
  display: flex;
  flex-direction: column;
  transform: translateY(2px);

  :first-child {
    cursor: pointer;
  }

  div {
    display: flex;
    align-self: center;
  }

  .numberOfReactsDiv {
    font-size: 10px;
    font-weight: 300;
  }

  svg {
    fill: #f50c0c;
  }
}

@keyframes toReact {
  90%{
    transform: scale(125%) translateY(2px);
  }
  95%{
    transform: scale(90%) translateY(-1px);
  }
  100%{
    transform: none;
  }
}

@keyframes toRemoveReact {
  90%{
    transform: scale(75%);
  }
  95%{
    transform: scale(90%);
  }
  100%{
    transform: none;
  }
}
