#PinnedPageDiv {
  position: fixed;
  left: 0;
  top: 85px;
  width: 100vw;
  //height: 120vw;
  height: calc(100vh - 85px);
  overflow: auto;
}

#DeleteConfirmationDiv {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-Index: 9999999999999999;
}

#DeleteConfirmationBackdrop {
  position: 'absolute';
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

#DeleteConfirmationBody {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  maxWidth: 380px;
  text-align: center;
  color: white;
}

#ButtonsInRemoveDiv {
  display: flex;
  margin-top: 20px;
  margin-left: 30%;
  gap: 35%;

  div {
    cursor: pointer;
  }
}

@media only screen and (max-width: 1024px) {
  #PinnedPageDiv {
    top: 10px;
  }
}
