#MediaPlayerDiv {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  //z-index: 2147483647;

  //& :first-child {
  div {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

#BackButtonInMediaPlayer {
  position: absolute;
  left: 20px;
  top: 20px;
  transform: scale(1.3);
  cursor: pointer;
  width: 30px;
  height: 30px;
  //z-index: 99999999999999999;
  border-radius: 50%;
  background-color: rgba(10, 10, 10, 0.7);

  svg {
    transform: translate(6px, 4px);
  }

  &:hover {
    background-color: rgba(40, 40, 40, 0.8);
  }
}
