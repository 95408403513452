#RecipeCard {
  position: fixed;
  left: 50%;
  top: calc(50% + 42px);
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 53vw;
  min-width: 700px;
  min-height: 466px;
  max-width: 900px;
  max-height: 600px;
  /*width: 100vw;
  height: calc(100vw * 0.66666);
  max-width: 800px;
  max-height: 536px;
  left: 50%;
  top: calc(50% + 42px);
  transform: translate(-50%, -50%);*/


}

 #ImageAndTextInRecipe {
   position: relative;
   display: flex;
   width: 100%;
   height: 100%;
   border-radius: 25px 20px 20px 20px;
   //box-shadow: -1px -1px 3px #111, 1px -1px 3px #111, -1px 1px 3px #111, 1px 1px 3px #111;

   img {
     border-radius: 25px 0 0 20px;
     width: 100%;
     height: 100%;
     cursor: default;
     //overflow: hidden;
     //object-fit: cover;
     //height: 50vh;
   }
 }


#ImageInRecipeCardDiv {
  position: relative;
  width: 45%;

}

#TextInRecipeCard {
  position: relative;
  width: 55%;
}

@media only screen and (max-width: 1024px) {
  #RecipeCard {
    top: calc(50% - 25px);
  }
}

@media only screen and (max-width: 700px) {
  #RecipeCard {
    width: 100%;
    height: calc(100vh - 55px);
    min-width: inherit;
    min-height: inherit;
    max-width: 500px;
    max-height: none;
    overflow: auto;
  }

  #ImageAndTextInRecipe {
    flex-direction: column;
    height: auto;

    img {
      border-radius: 25px 20px 0 0;
    }
  }

  #ImageInRecipeCardDiv, #TextInRecipeCard{
    width: 100%;

  }
}
