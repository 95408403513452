#AppReactDiv {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 999999999;
  right: 4px;
  bottom: 10px;
  gap: 5px;
  width: 50px;
  height: 50px;

  svg {
    //display:
    //position: relative;
    transform: scale(1.7);
    fill: #f50c0c;
  }
}

#AppReactButton {
  display: flex;
  align-self: center;
  cursor: pointer;
}

#NumOfAppReacts {
  position: relative;
  text-align: center;
  pointer-events: none;
  //display: none;
  background-color: transparent;
  //transform: translateY(-15px);
  font-weight: 200;
  font-size: 12px;
}

@media only screen and (max-width: 1024px) {
  #AppReactDiv {
    bottom: 80px;
    z-index: auto;
  }
}
