#SliderInMore {
  position: absolute;
  padding: 0 10px 20px 10px;
  transform: translateX(100%);
  text-align: left;
  animation: displaySlider 400ms ease forwards;
  font-weight: 300;

  .headerInSlider {
    font-size: 20px;
    font-weight: 400;
    margin: 15px 0 10px 0;
  }

  a {
    color: #4287f5;
    text-decoration: none;
  }
}

@keyframes displaySlider {
  100% {
    transform: translateX(0%);
  }
}

@keyframes hideSlider {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
