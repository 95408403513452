#MoreDiv {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 999999999;
}

#MoreBodyDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  left: 50%;
  top: 50%;
  //width: 0;
  //height: 0;
  //background-color: red;
  transform: translate(-50%, -50%);
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;

  &.theme-dark {
    background-color: rgb(0, 0, 0);

    .optionInMore {
      cursor: pointer;
      height: 20px;
      padding: 10px;
      background-color: #252525;
    }

    .optionInMore:hover {
      background-color: #202020;
      transition: background-color 200ms
    }
  }

  &.theme-light {
    background-color: rgb(255, 255, 255);

    .optionInMore {
      cursor: pointer;
      height: 20px;
      padding: 10px;
      background-color: rgb(200, 200, 200);;
    }

    .optionInMore:hover {
      background-color: #dddddd;
      transition: background-color 200ms;
    }
  }
}
