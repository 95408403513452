.backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.existButtonInBackdrop {
  position: fixed;
  left: 20px;
  top: 20px;
  transform: scale(1.3);
  cursor: pointer;
  width: 30px;
  height: 30px;
  z-index: 9999999999999999999999999999999;
  border-radius: 50%;
  background-color: rgba(10, 10, 10, 0.7);

  svg {
    transform: translate(6px, 4px);
  }

  &:hover {
    background-color: rgba(40, 40, 40, 0.8);
  }
}
